import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { fetchPostApi } from '../services/loginservice';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../App';
import LoginForm from '../components/LoginForm';

const Login = () => {
  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const navigate = useNavigate();
  const { login } = useUser(); // Get the login function from context
  const [loading, setLoading] = useState(false);

  const handlePasswordVisibilityToggle = () => {
    setPasswordShown(!passwordShown);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'username') setUsername(value);
    if (name === 'password') setPassword(value);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      if (user.userRole === 'admin') {
        navigate('/dashboard');
      } else {
        navigate('/userForms');
      }
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormDisabled(true);
    setLoading(true);

    // Encrypt the password before sending it
    const encryptedPassword = CryptoJS.SHA256(password).toString();

    const obj = { userName, password: encryptedPassword };
    const response = await fetchPostApi('/user/signin', obj);
    if (response.status === 200) {
        setLoading(false);
        const res = await response.json();
        Swal.fire({
            title: 'Success!',
            text: 'User Login Successful',
            icon: 'success',
            confirmButtonText: 'OK'
        }).then((result) => {
            if (result.isConfirmed) {
                const userData = {
                    userRole: res.data.user.role,
                    username: res.data.user.userName,
                    userId: res.data.user.id,
                    companyId: res.data.user.companyId,
                    firstName: res.data.user.firstName,
                    lastName: res.data.user.lastName,
                    token: res.token, // Store the JWT token received from the backend in userData
                    companyDefPass: res.data.user.companyDefaultPassword // added for reset to def password by admin
                };
                // console.log("Token", res.token);
                // console.log("EncPAss", encryptedPassword);
                // console.log("Comp data", res.data.companyData);
                localStorage.setItem('user', JSON.stringify(userData));
                localStorage.setItem('loginTime', Date.now()); // Setting login time in milliseconds
                login(userData); // Call the login function from context

                // For new OrgAdmin - Check if OrgAdmin and has multiple companies
                if (res.data.user.role === 'orgadmin') {
                // OrgAdmin with multiple companies
                    localStorage.setItem('companyData', JSON.stringify(res.data.companyData));
                    //console.log("Local Storage All Data: ", localStorage);
                    navigate('/orgAdmin/selectCompany'); // Redirect to new OrgAdmin page
                } else {
                // Redirect based on user role
                if (userData.userRole === 'admin') {
                    // console.log("ELSE TOO");
                    //console.log("Local Storage All Data: ", localStorage);
                    navigate('/dashboard');
                } else {
                    //console.log("Local Storage All Data: ", localStorage);
                    navigate('/userForms');
                }
            }
        }
      });
    } else {
        setLoading(false);
        // Handle different server responses
        if (response.status === 401) {
            Swal.fire({
                title: 'Failed!',
                text: "Username or password is incorrect",
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
        else if (response.status === 402) {
            Swal.fire({
                title: 'Failed!',
                text: "This account is Disabled! Please contact Admin!",
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
        else if (response.status === 403) {
            Swal.fire({
                title: 'Failed!',
                text: "This account is not a part of any company! Please Contact Admin!",
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }else {
            Swal.fire({
                title: 'Error!',
                text: "Server not working",
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
        setFormDisabled(false);
    }
    setLoading(false);
}

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100 relative overflow-hidden">
    {/* Background Image */}
    <div
      className="absolute w-full h-full bg-cover bg-center"
      style={{ backgroundImage: 'url(/images/signin.jpeg)' }}
    ></div>

    {/* Background Overlay */}
    <div className="absolute w-full h-full bg-gray-800 opacity-80"></div>

    {/* LoginForm.jsx component for Form view incorporated here */}
    <LoginForm
      onSubmit={handleSubmit}
      formDisabled={formDisabled}
      passwordShown={passwordShown}
      handlePasswordVisibilityToggle={handlePasswordVisibilityToggle}
      handleChange={handleChange}
      loading={loading} // Pass loading state to LoginForm
    />
          {/* Page-level Full-Screen Loading Spinner */}
          {loading && (
        <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-20">
          {/* This SVG is used for the ripple effect loding spinner circle*/}
          <svg className="animate-spin h-16 w-16 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <circle cx="12" cy="12" r="10" stroke="rgb(234 179 8)" strokeWidth="4" fill="none">
              <animate attributeName="r" values="10;5;10" dur="1s" keyTimes="0;0.5;1" repeatCount="indefinite" />
            </circle>
          </svg>
        </div>
      )}
    </div>
  );
};

export default Login;
