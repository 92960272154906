import 'tailwindcss/tailwind.css';

const LoginForm = ({ onSubmit, formDisabled, passwordShown, handlePasswordVisibilityToggle, handleChange, loading  }) => {
  return (
    <div className="relative bg-white bg-opacity-40 backdrop-blur-lg p-8 rounded-lg shadow-lg w-full max-w-md z-10">
      <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Login to Your Account</h2>
      <form id="loginForm" onSubmit={onSubmit}>
        <div className="mb-4">
          <label htmlFor="username" className="block text-sm text-gray-800 mb-2">Username <span className="text-red-500">*</span></label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="Enter your username"
            onChange={handleChange}
            disabled={formDisabled}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>
        <div className="mb-4 mt-4">
          <label htmlFor="password" className="block text-sm text-gray-800 mb-2">Password <span className="text-red-500">*</span></label>
          <div className="relative">
            <input
              type={passwordShown ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Enter your password"
              onChange={handleChange}
              disabled={formDisabled}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <span
              onClick={handlePasswordVisibilityToggle}
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            >
              {passwordShown ? (
                // This SVG is used for view passowrd icon that let's the users view their entered password
                <svg className="h-6 w-6" fill="none" stroke="rgb(234, 179, 8)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <circle cx="12" cy="12" r="3" fill="rgb(234, 179, 8)"></circle>
                  <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68" />
                  <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"  d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                </svg>

              ) : (
                // This SVG is used for hidden passowrd icon to indicate user that the password is hidden
              <svg className="h-6 w-6" fill="none" stroke="rgb(234, 179, 8)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="3" fill="rgb(234, 179, 8)"></circle>
                <path d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"/>
                <path d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61" ></path>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"  d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                <line x1="2" x2="22" y1="2" y2="22" stroke="rgb(234, 179, 8)" strokeWidth="3" ></line>
              </svg>

              )}
            </span>
        </div>
      </div>
      <button
        type="submit"
        className="w-full py-3 px-4 bg-yellow-500 text-white font-semibold rounded-lg shadow-md hover:bg-yellow-600 transition duration-300 mt-6"
        disabled={formDisabled}
      >
        Login
      </button>
    </form>
  </div>
  );
};

export default LoginForm;
