import config from '../config';

export const fetchPostApi = (endpoint, jsonBody) => {
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
  };

  return fetch(`${config.API_BASE_URL}${endpoint}`, {
    body: JSON.stringify(jsonBody),
    mode: 'cors',
    method: 'POST',
    headers: headers,
  })
    .then((res) => res)
    .catch((err) => err);
};